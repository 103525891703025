import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

const Sidebar = () => {
  const {
    allRecentPosts: { edges: recentPosts },
    allWpCategory: { nodes: categories },
  } = useStaticQuery(
    graphql`
      query Sidebar {
        allRecentPosts: allWpPost(
          sort: { order: DESC, fields: date }
          limit: 3
        ) {
          edges {
            node {
              id
              title
              uri
            }
          }
        }
        allWpCategory {
          nodes {
            id
            name
            uri
          }
        }
      }
    `
  );

  return (
    <aside className="col-lg-3">
      <div className="blog-sidebar-inside">
        <div>
          <h6 className="mb-1">Recent Posts</h6>
          <ul>
            {recentPosts.map(({ node: post }) => {
              return (
                <li key={post.id}>
                  <Link className="blog-sidebar-recent-link" to={post.uri}>
                    {post.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <h6 className="mb-1">Categories</h6>
          <ul>
            {categories.map((category) => {
              return (
                <li className="flex" key={category.id}>
                  <Link
                    className="blog-sidebar-category-link"
                    to={category.uri}
                  >
                    {category.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
