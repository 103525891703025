import React from "react";
import Banner from "../sections/banner";
import Sidebar from "./sidebar";
import Content from "../utilities/content";

const BlogElem = ({
  banner,
  blogContent,
  blogTitle,
  children,
  pageContext,
  location,
}) => {
  return (
    <>
      {banner.image && (
        <Banner data={banner} pageContext={pageContext} location={location} />
      )}
      <section id="blog">
        <div className="container">
          {blogContent ||
            (blogTitle && (
              <div className="blog-info">
                {blogTitle && <h1>{blogTitle}</h1>}
                {blogContent && <Content>{blogContent}</Content>}
              </div>
            ))}
          <div className="row">
            <div className="col-lg-9">{children}</div>
            <Sidebar />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogElem;
